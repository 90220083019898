* {
  margin: 0;
}

.app {
  background-color: #e9e9e3;
  height: 100vh;
  display: grid;
  place-items: center;
}

.app__container {
  box-shadow: -1px 4px 20px -6px rgba(0, 0, 0, 0.3);
  display: flex;
  width: 85vw;
  height: 90vh;
  background-color: rgb(250, 250, 250);
}
